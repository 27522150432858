.masonry-grid {
  display: flex;
  width: 85vw;
  /* max-height: 95vh; */
  box-sizing: border-box;
}
.masonry-grid_column {
  padding: 2px; /* Consente un po' di margine tra le immagini */
  background-clip: padding-box;
}   


.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
}

.overlay-content {
  position: relative;
width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  z-index: 1000022;
}

.fullscreen-image {
  max-width: 100%;
  max-height: 97%;
  
  object-fit: contain;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
}

.close-button {
  position: absolute;
  top: 20px;
  right: 10px;
  background: transparent;
  border: none;
  color: white;
  font-size: 30px;
  cursor: pointer;
}

.prev-button,
.next-button {
  background: transparent;
  border: none;
  color: white;
  font-size: 50px;
  cursor: pointer;
}

.prev-button {
  position: absolute;
  left: 10px;
}

.next-button {
  position: absolute;
  right: 10px;
}

.masonry-grid div {
  overflow: hidden; /* Nasconde la parte dell'immagine che esce dai bordi */
}

.masonry-grid img {
  width: 100%;
  
  object-fit: cover;
  cursor: pointer;
  transition: transform 0.5s ease; /* Transizione fluida per l'effetto di zoom */
}

.masonry-grid img:hover {
  transform: scale(1.1); /* Effetto zoom al 110% */
}